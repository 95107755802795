@import '../../styles/customMediaQueries.css';

.root {
}

@media screen and (max-width: 768px) {
  .desktopModal {
    display: none !important;
  }
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  height: 592px;
  min-height: 100%;
  /* height: calc(100vh - var(--topbarHeightDesktop)); */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    224deg,
    rgba(255, 255, 255, 0.0032387955182072714) 0%,
    rgba(255, 255, 255, 0) 43%,
    rgba(229, 239, 255, 1) 94%
  );
  padding: 64px 0 47px;

  @media (max-width: 1023px) {
    height: 100%;
  }

  @media (max-width: 767px) {
    background: linear-gradient(90deg, #e5efff 0%, rgba(255, 255, 255, 0) 100%);
    padding: 48px 0 48px;
  }
}

.topBarLoginBefore nav {
  background-color: transparent;
  box-shadow: none;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 0px;

  @media (--viewportLarge) {
    justify-content: center;
    /* padding-top: 60px; */
  }
}

.sections {
  margin: 0;

  & img {
    width: 100%;
  }
}

/* .section {
  overflow: auto;
} */

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  composes: marketplaceSectionWrapper from global;
}

.sectionContentFirstChild {
  composes: sectionContent;
  /* margin-top: 3vh; */
}

.LandingPageAfter {
  composes: marketplaceSectionWrapper from global;
  @media (min-width: 768px) {
    margin-top: 8px;
  }
}
