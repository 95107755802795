@import '../../styles/customMediaQueries.css';

.container {
  margin-top: 48px;

  @media (--viewportSmall) {
    margin: 0 auto;
    margin-top: 96px;
    max-width: 1440px;
    padding: 0 136px;
  }
}





/* hero section */
.hero {
  flex-direction: column;
  text-align: center;
  padding: 50px 20px;
  background: #f3fffd;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    text-align: left;

    padding: 100px;
  }
}

.sectionHeading {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 32px;
  line-height: 1.4;

  @media (--viewportSmall) {
    font-size: 48px;

  }
}

.heroMainTitle {
  @media (max-width: 767px) {
    text-align: center;
  }

  font-family: 'M PLUS Rounded 1c',
  sans-serif;
}

.heroContent,
.heroImage {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heroImage img {
  width: 100%;
  max-width: 600px;
  align-self: center;
}

.heroContent h1 {
  font-size: 42px;

  @media (--viewportSmall) {
    font-size: 96px;
  }
}

.heroSubTitle {
  color: rgba(6, 6, 47, 0.65);
  font-size: 16px;
  margin-bottom: 1.5rem;

  @media (--viewportSmall) {
    font-size: 22px;
    margin-top: 20px;
    font-weight: 300;
    line-height: 34px;
    word-wrap: break-word;
  }
}

.heroSubTitleDesktopOnly {
  display: none;

  @media (--viewportSmall) {
    display: inline;
  }
}

.directlyText {
  color: #e75b57;
  display: inline-block;
  font-weight: 700;
  position: relative;
  padding: 2px 10px;
  border-radius: 100px;
}

.subtitleBorder {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
}

.searchInput {
  position: relative;
  max-width: 600px;
  margin-top: 24px;

  @media (max-width: 1023px) {
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    margin: 0;
  }

  & .searchIcon {
    position: absolute;
    top: 16px;
    left: 28px;
    z-index: 1;


    @media (max-width: 1023px) {
      top: 15px;
      left: 16px;
    }

    & svg {
      @media (max-width: 1023px) {
        width: 36px;
        height: 36px;
      }
    }
  }

  & input {
    background: #ffffff;
    border: 2px solid #f0a840;
    box-shadow: 0px 36px 60px rgba(193, 193, 193, 0.12);
    border-radius: 100px;
    padding: 27px 0 27px 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #1f403ba8;
    height: 64px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #1f403b;
      opacity: 0.4;
    }

    @media (max-width: 1023px) {
      height: 56px;
      padding: 23px 0 23px 40px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  & button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 50px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;

    @media (max-width: 1023px) {
      width: 102px;
      height: 44px;
      font-size: 14px;
      line-height: 16px;
      top: 6px;
      right: 6px;
      gap: 6px;
    }

    & svg {
      width: 36px;
      height: 36 px;
    }
  }
}

.popularSearch {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;

  @media (--viewportSmall) {
    margin-top: 32px;
    justify-content: left;
    gap: 8px;
    align-items: center;
  }
}

.popularSearch p {
  text-align: center;
}

.popularSearch>p {
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}

.popularSearch .categoryText {
  padding: 2px 10px;
  border-radius: 100px;
}

.popularCategoriesTitle {
  font-size: 16px;
}

.features {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 50px 20px;
  }
}

.feature {
  flex: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  text-align: center;
  gap: 0;

  @media (--viewportSmall) {
    gap: 16px;
    text-align: left;
    border: 1px solid #eeeeee;
    border-radius: 8px;
  }
}

.featureContent h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.featureContent p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #060531;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.featureIcon img {
  width: 80px;
  height: 80px;

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
}

.trendingSearch {
  display: flex;
  flex-direction: column;

  text-align: center;

  @media (max-width: 767px) {
    padding: 50px 20px;
  }
}

.trendingSearchItems {
  display: flex;
  justify-content: space-between;
  overflow: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.trendingSearchItem {
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: center;
}

.trendingSearchItemContent img {
  width: 100%;
  height: 160px;
  border-radius: 50%;
  object-fit: fill;

  @media (max-width: 767px) {
    width: 100px;
    height: 100px;
  }
}

.trendingSearchItemContent p {
  font-size: 18px;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  margin-top: 10px;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.howItWorks {
  text-align: center;
  background: #f6f7f9;
  margin: 96px 136px;
  padding: 50px;
  border-radius: 16px;

  @media (max-width: 767px) {
    margin: 0;
    padding: 50px 24px;
  }
}

.guideTab {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #eeeeee;
  /* Continuous border */
  border-radius: 100px;
  /* Apply border-radius to both buttons */
  width: 240px;

  @media (--viewportSmall) {
    width: 340px;
  }
}

.guideTab button {
  margin: 0;
  border: none;
  /* Remove default button borders */
  border-radius: 100px;
  /* Remove default button border-radius */
  height: 54px;
  font-size: 16px;
  color: black;
  text-transform: none;
}

.guideTabItem {
  flex: 1;
  border-radius: 12px;
  /* Apply border-radius to both buttons */
}

.guideContent {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 36px;
    text-align: left;
  }
}

.guideText h3 {
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.guideText p {
  color: #4b5574;
  font-size: 16px;

  line-height: 28px;
  word-wrap: break-word;
}

.guideItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
  }
}

.guideIcon img {
  width: 76px;
  height: 76px;

  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
  }
}

.guideItem h3 {
  margin: 15px;

  @media (max-width: 767px) {
    margin: 0;
  }
}

.guideText {
  display: flex;
  flex-direction: column;
}

.explore {
  text-align: center;

  @media (max-width: 767px) {
    padding: 50px 20px;
  }
}

.exploreItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.headingContainer {
  width: 200px;
  margin: 0 auto;
}

.exploreItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
  border: solid 1px #eeeeee;
  margin: 20px;
  border-radius: 10px;
  width: calc(33.3333% - 10px);

  @media (max-width: 767px) {
    padding: 0;
    /* 3 items per line */
    flex-basis: 30%;
    margin: 5px;
  }

  @media (--viewportSmall) {
    width: 170px;
  }
}

.tag {
  height: 32px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 20px;
  width: fit-content;
  align-self: center;
  line-height: 2.5;
}

.exploreItem:hover {
  border: solid 1px #68cebc;
}

.exploreItemContent {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  height: 180px;
  margin: 10px 0;
  align-items: center;

  @media(--viewportSmall) {
    margin: 20px 0;
  }
}

.exploreItemContent img {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
  }
}

.chartSection {
  background: #f0a8400d;
  display: flex;
  flex-direction: column;

  @media(--viewportSmall) {
    flex-direction: row;
    margin-top: 96px;
  }
}

.slogan,
.chart {
  flex: 1;
  padding: 60px;

  @media (max-width: 767px) {
    padding: 60px 30px;
  }
}

@media(--viewportSmall) {
  .slogan {
    order: -1;

  }
}

.chart {
  @media (max-width: 767px) {
    padding: 0;
    padding-top: 50px;
  }
}

.slogan h1 {
  width: 450px;
  font-size: 48px;
  max-width: 100%;
  line-height: 1.2;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 32px;
  }
}

.sloganItem {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.chart img {
  width: 100%;
}

.sloganItem img {
  width: 24px;
  height: 24px;
  align-self: center;
}

.qAndA {
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
    padding: 50px 0;
  }
}

.qAndA h1 {
  @media (max-width: 767px) {
    text-align: center !important;
    font-size: 32px;
    line-height: 1.4;
  }
}

.qAndA>div h1 {
  display: none;
}

.helperLink {
  height: 100%;
}

.lastItem {
  margin: 0 auto;

  @media (--viewportSmall) {
    margin: 0;
  }
}

/* how it works vector */
.vectorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 122px;
  position: relative;
  top: 78px;
}

.vectorImage {
  width: 150px;
}

.vectorImage1 {
  position: absolute;
  left: 25.7%;
  width: 150px;
  display: none;

  @media(--viewportSmall) {
    display: block;
  }

  @media(--viewportXLarge) {
    width: 190px;
    ;
  }
}

.vectorImage2 {
  position: absolute;
  left: 44.7%;
  width: 150px;
  display: none;

  @media(--viewportSmall) {
    display: block;
  }

  @media(--viewportXLarge) {
    width: 190px;
  }
}

.vectorImage3 {
  position: absolute;
  left: 63.7%;
  width: 150px;
  display: none;

  @media(--viewportSmall) {
    display: block;
  }

  @media(--viewportXLarge) {
    width: 190px;
  }
}

.sloganHeading {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.sloganButton {

  margin-top: 2rem;
  text-align: center;
  padding: 1rem 1.875rem;
  border-radius: 100px;
  background-color: #68cebc;

  @media(--viewportSmall) {
    display: inline-block;
  }
}

.buttonText {
  color: #060531;
  font-size: 18px;
  font-weight: 700;
}

.scrollButtonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;

  @media (--viewportSmall) {
    display: none;
  }
}

.scrollButton {
  border: none;
  background-color: #F6F7F9;
  border-radius: 50%;
  width: 48px;
  height: 48px;

  &:active {
    background-color: #68cebc;
  }
}
